
import './App.css';
//import StripeCheckout from 'react-stripe-checkout';
import { useState } from 'react';
//import Payment from '../../client/src/pages/Payment'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from '../src/pages/Home';
import { Dashboard } from '../src/pages/Dashboard';
import Completion from '../src/pages/Completion';
import { EtudiantsInscrits } from './pages/EtudiantsInscrits';


import { LANGUAGES } from "../src/constants/index";

import { useTranslation } from 'react-i18next'


//const KEY = process.env.REACT_APP_STRIPE;

function App() {
  // const [stripeToken, setStripeToken] = useState(null);

  // const onToken = (token) => {
  //   setStripeToken(token);
  // }
  // console.log(stripeToken);
  const { i18n, t } = useTranslation();


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          <Route index element={<Home />} />
          {/* <Route path="Payment" element={<Payment />} /> */}
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Inscrits" element={<EtudiantsInscrits />} />
          <Route path="/Completion" element={<Completion />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </BrowserRouter>

    </div >
  );
}

export default App;
