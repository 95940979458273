import { Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

export const Navigation = () => {
    <div>
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
        </a>
    </div>
}