import React, { image, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import banniere from "../../images/PictureISMI.png"
import signature from "../../images/SignatureISMI.png"
import ReactPDF from '@react-pdf/renderer';
// Create styles


const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        paddingHorizontal: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 12,
        textAlign: "center",
        fontSize: "12px"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "100%",
    },
    right: {
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "0.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10px",
        // marginTop: "170px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    }
});




// Create Document Component
export const CertificatDInscription = (props) => {

    return (

        <Document>
            <Page size="A4"
                style={styles.page}
            >
                <View>

                    <Image
                        //style={styles.image} 
                        src={banniere}
                    />
                </View>
                <View style={styles.left}>

                    <Text

                    >N° d’enregistrement auprès du préfet IDF :
                    </Text><br />
                    <Text >

                        11 75 44 681 75
                    </Text>
                </View>
                <View>

                </View>
                <View>

                    <Text
                        style={{ margin: "50px" }}
                    >ATTESTATION DE SUIVI ET DE FIN DE FORMATION</Text><br />
                </View>
                <View >
                    <Text
                        style={styles.text}
                    >Je soussignée, Madame Audrey DUONG, Directrice
                        de l’Institut de
                    </Text>
                    <br />
                    <Text style={styles.text}>

                        Stratégie et de Management International,
                        établissement d’enseignement supérieur privé, certifie que :
                    </Text>
                </View>
                <View>
                    <Text
                        style={styles.text}

                    >

                        Nom de l'étudiant: {props.nom} </Text>
                    <br />
                    <Text style={styles.text}>

                        Prénom de l'étudiant: {props.prenom}
                    </Text><br />
                    <Text style={styles.text}>

                        Né(e) le : {props.dateDeNaissance}
                    </Text><br />
                    <Text style={styles.text}>

                        De nationalité: {props.nationalite}
                    </Text>



                    <br />

                </View>
                <View>
                    <Text
                        style={styles.text}
                    >A été accepté (e ) et est bien inscrit (e) au sein de notre institut pour la formation </Text>
                    <br />
                    <Text style={styles.text}>{props.nomDeLaFormation} qui se déroule sous l'autorité

                        du </Text> <br />
                    <Text style={styles.text}>

                        Ministère de l’Education Nationale enregistré au RNCP. </Text><br />
                </View>
                <View style={styles.text} >
                    <Text >


                        Date de rentrée de formation :	{props.dateDebutFormation}
                    </Text><br />
                    <Text>

                        Date de fin de formation :	{props.debutFinDeFormation}
                    </Text><br />
                    <Text>

                        Durée de la formation :	1100 heures
                    </Text><br />

                    <Text>

                        Montant versé non remboursable : 375 euros
                    </Text><bt />


                </View >
                <View><Image src={signature} style={styles.signature} /></View><br />
                <View style={styles.text}>
                    <Text style={styles.text}>Compte tenu des diverses contraintes administratives (attente de visa, ...),
                        l'étudiant(e) </Text><br />
                    <Text>dispose d'une dérogation de rentrée tardive de 60 jours maximum
                        après la date de </Text><br />
                    <Text>rentrée pour se présenter en formation.</Text>
                    <Text
                        style={styles.right}
                    >
                        Fait pour valoir ce que de droit,</Text><br />
                    <Text style={styles.right}> à Paris,
                        le {new Intl.DateTimeFormat('fr-fr').format(Date.now())}
                    </Text> <br />
                    <Text style={styles.right}>
                        Audrey DUONG, Directrice.
                    </Text>
                </View>
                <View
                    style={styles.footer}
                >
                    <Text >

                        © Institut de Stratégie et de Management International - Mai 2024 </Text><br />
                    <Text>

                        Tél: 01 71 20 45 88, Site internet : https://www.ismi.fr, E mail : contact@ismi.fr Siret: 50925025400018
                    </Text><br />
                    <Text>

                        Accueil sur RDV de 15h à 18h du lundi au vendredi à ISMI, Tour Essor, Rte des Petits Ponts,75019 Paris
                    </Text><br />
                    <Text>

                        Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75 020 Paris
                    </Text><br />
                    <Text>

                        Code APE : 7022Z N°d’enregistrement auprès de la préfecture: 11754468175 - Marketies
                    </Text><br />
                    <Text>

                        Copyright réservé - Reproduction strictement interdite sans accord express écrit validé par l’ISMI
                    </Text>

                </View>
            </Page >
        </Document >

    );
}