import React, { useEffect, useState } from 'react'
import img from '../images/banniereISMI.png'
import Payment from './Payment'
import { useNavigate } from 'react-router-dom'
import SignatureCanvas from "react-signature-canvas"
import axios from 'axios';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { AttestationDeSuiviEtDeFinDeFormation } from '../components/PDFs/AttestationDeSuiviEtDeFinDeFormation'
import { PopupWidget } from "react-calendly";
import { PopupButton } from "react-calendly";
import "../App.css"
import { InlineWidget } from "react-calendly";

export const Home = () => {

    const navigate = useNavigate();
    const [sign, setSign] = useState()
    const [url, setUrl] = useState();
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);
    const [cvMedia, setCvMedia] = useState()
    const [diplomeMedia, setDiplomeMedia] = useState()
    const [lettreDeMotivationMedia, setLettreDeMotivationMedia] = useState()
    const [cniMedia, setCniMedia] = useState()
    const [error, setError] = useState(false)
    const [phone, setPhone] = useState('');
    const [ficheDePosteMedia, setFicheDePosteMedia] = useState()

    const handleChange = (e) => {
        console.log(e.target.value);

        const value = e.target.value;
        // const activeData = document.getElementById("my-checkbox").checked;

        // console.log(activeData)



        if (e.target.checked) {
            if (selected.length > maximumCheckBox) {
                alert("Un seul choix est possible pour certaines questions");
                e.target.checked = false;
                return;
            }

            setSelected([...selected, value])
        } else {
            setSelected(selected.filter(item => item !== value))
        }

        // console.log(activeData)
    }
    console.log(selected)



    const [coordonnees, setCoordonnees] = useState({
        nom: "",
        prenom: "",
        email: "",
        adresse: "",
        complementAdresse: "",
        codePostal: "",
        ville: "",
        creneauHoraire: ""
    })

    const [client, setClient] = useState({
        // coordonnees: [],
        nom: "",
        prenom: "",
        adresse: "",
        complementAdresse: "",
        codePostal: "",
        ville: "",
        numero: "",
        creneauHoraire: "",
        specialiteDiplome: "",
        autreObjectif: "",
        nonPresenterDiplomePourquoi: "",
        fonctionExperienceProfessionelle: "",
        autreRaisonObjectifFormation: "",
        attentesEtMotivations: "",
        motivationsPourSuivreLaFormation: "",
        depuisCombienDeTempsInteressesParLaFormation: "",
        qualitesPourEtreUnBonProfessionnel: "",
        coordonneesReferentAlternance: "",
        besoinsSpecifiquesHandicap: "",
        besoinsCreationActivite: "",
        autresInformationsComplementaires: "",
        checkboxesQuestionnaire: [],
        motivation: "",
        attenteDeLaFormation: "",
        depuisCombienDeTempsInteressesParLaFormation: "",
        signatureUrl: ""
        // documentsTelecharges: [],
        // cvMedia: "",
        // diplomeMedia: "",
        // lettreDeMotivationMedia: "",
        // cniMedia: ""
    })

    // const [documentsTelecharges, setDocumentsTelecharges] = useState({
    //     cvMedia: "",
    //     diplomeMedia: "",
    //     lettreDeMotivationMedia: "",
    //     cniMedia: ""
    // })

    const handleClear = async (e) => {
        e.preventDefault();
        //sign.Clear();
    }

    // const [newDocuments, setNEwDocuments] = useState({
    //     cvMedia: '',
    //     diplomeMedia: '',
    //     lettreDeMotivationMedia: '',
    //     cniMedia: ''
    // });

    const maximumCheckBox = 24;
    const minimumCheckBox = 14;

    function handleInput(event) {
        const newClient = { ...client }
        newClient[event.target.name] = event.target.value;
        setClient(newClient);
        console.log(newClient);

        // if(event.target.value.length){

        // }

        console.log(event.target.value.length)
        const newCoordonnees = { ...coordonnees }
        newCoordonnees[event.target.name] = event.target.value;
        setCoordonnees(newCoordonnees);
        console.log(newCoordonnees)

        // console.log(event.target.files[0])
        // const newDocument = { ...documentsTelecharges }
        // newDocument[event.target.name] = event.target.value;
        // setDocumentsTelecharges(newDocument);
        // console.log(newDocument);//
        // setFormData({ ...formData, [event.target.name]: event.target.value });
        // console.log(formData);
    }

    console.log(cvMedia);
    function handleSave(event) {
        event.preventDefault();

        //     // switch (document) {


        //     //     case document.forms["myForm"]["nom"].value == "":
        //     //         setError(true)
        //     //         break;
        //     //     case document.forms["myForm"]["prenom"].value == "":
        //     //         setError(true)
        //     //         break;
        //     //     case document.forms["myForm"]["adresse"].value == "":
        //     //         setError(true)
        //     //         break;
        //     //     // case document.forms["myForm"]["numero"].value == "":
        //     //     //     setError(true)
        //     //     //     break;
        //     //     case document.forms["myForm"]["codePostal"].value == "":
        //     //         setError(true)
        //     //         break;
        //     //     case document.forms["myForm"]["ville"].value == "":
        //     //         setError(true)
        //     //         break;
        //     //     case document.forms["myForm"]["email"].value == "":
        //     //         setError(true)
        //     //         break;
        //     //     // case client.prenom.length === 0:
        //     //     //     setError(true)
        //     //     //     break;
        //     //     // case client.adresse.length === 0:
        //     //     //     setError(true)
        //     //     //     break;
        //     //     // case client.ville.length === 0:
        //     //     //     setError(true)
        //     //     //     break;
        //     //     // case client.email.trim().length === 0 :
        //     //     //     setError(true)
        //     //     //     break;
        //     //     case client.codePostal.length === 0:
        //     //         setError(true)
        //     //         break;
        //     //     default:
        // }


        const config = { headers: { 'Content-Type': 'multipart/form-data' } }


        const filesUploaded = new FormData();
        filesUploaded.append('signatureUrl', url)
        filesUploaded.append('cvMedia', cvMedia)
        filesUploaded.append('diplomeMedia', diplomeMedia)
        filesUploaded.append('lettreDeMotivationMedia', lettreDeMotivationMedia)
        filesUploaded.append('cniMedia', cniMedia)
        filesUploaded.append('ficheDePosteMedia', ficheDePosteMedia)
        filesUploaded.append('nonPresenterDiplomePourquoi', client.nonPresenterDiplomePourquoi)
        filesUploaded.append('fonctionExperienceProfessionelle', client.fonctionExperienceProfessionelle)
        filesUploaded.append('autreRaisonObjectifFormation', client.autreRaisonObjectifFormation)
        filesUploaded.append('attentesEtMotivations', client.attentesEtMotivations)
        filesUploaded.append('motivationsPourSuivreLaFormation', client.motivationsPourSuivreLaFormation)
        filesUploaded.append('depuisCombienDeTempsInteressesParLaFormation', client.depuisCombienDeTempsInteressesParLaFormation)
        filesUploaded.append('qualitesPourEtreUnBonProfessionnel', client.qualitesPourEtreUnBonProfessionnel)
        filesUploaded.append('coordonneesReferentAlternance', client.coordonneesReferentAlternance)
        filesUploaded.append('numero', phone)
        filesUploaded.append('besoinsSpecifiquesHandicap', client.besoinsSpecifiquesHandicap)
        filesUploaded.append('besoinsCreationActivite', client.besoinsCreationActivite)
        filesUploaded.append('autresInformationsComplementaires', client.autresInformationsComplementaires)
        filesUploaded.append('checkboxesQuestionnaire', selected)
        filesUploaded.append('motivation', client.motivation)
        filesUploaded.append('attenteDeLaFormation', client.attenteDeLaFormation)
        // filesUploaded.append('nom', client.nom)
        // filesUploaded.append('prenom', client.prenom)
        // filesUploaded.append('adresse', client.adresse)
        // filesUploaded.append('complementAdresse', client.complementAdresse)
        // filesUploaded.append('codePostal', client.codePostal)
        // filesUploaded.append('ville', client.ville)
        // filesUploaded.append('creneauHoraire', client.creneauHoraire)

        //filesUploaded.append('coordonnees', coordonnees)
        filesUploaded.append('nom', client.nom)
        filesUploaded.append('prenom', client.prenom)
        filesUploaded.append('adresse', client.adresse)
        filesUploaded.append('ville', client.ville)
        filesUploaded.append('nocomplementAdressem', client.complementAdresse)
        filesUploaded.append('codePostal', client.codePostal)
        filesUploaded.append('creneauHoraire', client.creneauHoraire)
        filesUploaded.append('email', client.email)
        // filesUploaded.append('nom', client.nom)
        // filesUploaded.append('nom', client.nom)
        //     : client.nonPresenterDiplomePourquoi,
        //     : client.fonctionExperienceProfessionelle,
        // autreRaisonObjectifFormation: ,
        //     : client.attentesEtMotivations,
        // motivationsPourSuivreLaFormation: client.motivationsPourSuivreLaFormation,
        //     depuisCombienDeTempsInteressesParLaFormation: client.depuisCombienDeTempsInteressesParLaFormation,
        //         qualitesPourEtreUnBonProfessionnel: client.qualitesPourEtreUnBonProfessionnel,
        //             coordonneesReferentAlternance: client.coordonneesReferentAlternance,
        //                 numero: client.numero,
        //                     besoinsSpecifiquesHandicap: client.besoinsSpecifiquesHandicap,
        //                         besoinsCreationActivite: client.besoinsCreationActivite,
        //                             autresInformationsComplementaires: client.autresInformationsComplementaires,
        //                                 checkboxesQuestionnaire: selected,
        //                                     motivation: client.motivation,
        //                                         attenteDeLaFormation: client.attenteDeLaFormation,

        if (sign) {

            setUrl(sign.getTrimmedCanvas().toDataURL('Image/png'));
        }

        // if (selected.length < minimumCheckBox) {
        //     alert("Merci de répondre à toutes les questions")
        // };

        // console.log(cniMedia, cvMedia, lettreDeMotivationMedia, diplomeMedia)
        // console.log(document.forms["myForm"]["nom"].value,
        //     document.forms["myForm"]["prenom"].value, document.forms["myForm"]["adresse"].value,
        //     document.forms["myForm"]["numero"].value, document.forms["myForm"]["codePostal"].value,
        //     document.forms["myForm"]["ville"].value,
        //     document.forms["myForm"]["email"].value)


        if (cniMedia && cvMedia && lettreDeMotivationMedia && diplomeMedia && url && document.forms["myForm"]["nom"].value !== "" &&
            document.forms["myForm"]["prenom"].value !== "" && document.forms["myForm"]["adresse"].value !== ""
            && phone && document.forms["myForm"]["codePostal"].value !== "" &&
            document.forms["myForm"]["ville"].value !== "" &&
            document.forms["myForm"]["email"].value !== ""
            //&&
            //document.forms["myForm"]["attentesEtMotivations"].value !== ""
            //&&
            //document.forms["myForm"]["motivation"].value !== ""
            // && document.forms["myForm"]["attenteDeLaFormation"].value !== ""
        ) {

            axios.post(process.env.REACT_APP_API_URL, filesUploaded, config, {
                //coordonnees: coordonnees,
                // prenom: client.prenom,
                // nom: client.nom,
                // email: client.email,
                // adresse: client.adresse,
                // complementAdresse: client.complementAdresse,
                // codePostal: client.codePostal,
                // ville: client.ville,
                //documentsTelecharges: documentsTelecharges,
                // cniMedia: cniMedia,
                // diplomeMedia: diplomeMedia,
                // cvMedia: cvMedia,
                // lettreDeMotivationMedia: lettreDeMotivationMedia,
                // lettreDeMotivationMedia: lettreDeMotivationMedia,
                // specialiteDiplome: client.specialiteDiplome,

                //documentsTelecharges: documentsTelecharges
                //creneauHoraire: client.creneauHoraire
            }).then(response => console.log(response)).catch(e =>
                console.log("Merci de remplir tous les champs"))
        } else {
            alert("Merci de répondre à toutes les questions.")
        }
    }





    return (
        <div className="App">
            {/* <div>
                <AttestationDeSuiviEtDeFinDeFormation />
            </div> */}

            <div className="collapse" id="navbarToggleExternalContent">

                <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">L'institut</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">Compétences</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">Pourquoi l'ISMI ?</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">Les formations</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">Témoignages</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">Contact</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " href="#">S'inscrire</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="#">ISMI Campus en ligne</a>
                    </li>
                </ul>
            </div>
            <nav className="navbar navbar-dark bg-dark">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </nav>

            <div className='text-center'>
                <h1 id="header">Formulaire d'inscription</h1>
            </div>

            <div className="card-body">
                <img className="card-img-top" src={img} alt="Card image cap" />
            </div>
            <form name='myForm'>
                <div className="col-md-6 offset-md-3">
                    <h2>Votre état civil <span>*</span></h2>

                    <div style={{ margin: "20px" }}>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Prénom</label>
                            <div className="col-sm-10">
                                <input
                                    style={{ margin: "10px" }}
                                    type="text"
                                    name="prenom"
                                    required
                                    onChange={(e) => handleInput(e)}
                                    value={client.prenom}
                                    className="form-control" placeholder="Votre Prénom" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">NOM</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="nom"
                                    //value={nom}
                                    onChange={(e) => handleInput(e)}
                                    value={client.nom}
                                    required
                                    className="form-control" placeholder="Votre NOM" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Email</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    name="email"
                                    style={{ margin: "10px" }}
                                    onChange={(e) => handleInput(e)}
                                    value={client.email}

                                    required
                                    className="form-control" placeholder="Votre Adresse E-Mail" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Numéro de Téléphone</label>
                            <div className="col-sm-10">

                                <PhoneInput
                                    defaultCountry="fr"
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                />

                                {/* <input type="text"
                                    name="numero"
                                    style={{ margin: "10px" }}
                                    onChange={(e) => handleInput(e)}
                                    value={client.numero}

                                    required
                                    className="form-control" placeholder="Votre N° de téléphone" /> */}
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Adresse</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="adresse"
                                    onChange={(e) => handleInput(e)}
                                    value={client.adresse}


                                    required
                                    className="form-control" placeholder="Votre Adresse" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Complément</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="complementAdresse"

                                    onChange={(e) => handleInput(e)}
                                    value={client.complementAdresse}
                                    className="form-control" placeholder="Complément d'adresse" />
                            </div>

                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Code Postal</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    name="codePostal"
                                    style={{ margin: "10px" }}
                                    onChange={(e) => handleInput(e)}
                                    value={client.codePostal}
                                    required
                                    className="form-control" placeholder="Votre code postal" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Ville</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="ville"
                                    onChange={(e) => handleInput(e)}
                                    value={client.ville}
                                    required
                                    className="form-control" placeholder="Votre ville" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Nationalité</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    style={{ margin: "10px" }}
                                    name="nationalite"
                                    onChange={(e) => handleInput(e)}
                                    value={client.nationalite}
                                    required
                                    className="form-control" placeholder="Votre Nationalité" />
                            </div>
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                        <div className="form-group row">
                            {/* <label className="col-sm-2 col-form-label">Appel Téléphonique</label> */}
                            <div className="col-sm-10">

                                <InlineWidget url="https://calendly.com/contact-4378/rendez-vous-telephonique" />


                            </div>
                        </div>

                    </div>
                </div>


                <div className='row' >
                    <div className="col-md-6 offset-md-3">
                        <div >

                            <h3 htmlFor="exampleFormControlSelect1">1. Par quel(s) type(s) de formation(s) êtes-vous intéressé (e)?</h3><br />
                        </div>
                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginTop: 0 }} htmlFor="exampleFormControlSelect1">1.1. Quel est le niveau de formation souhaité? <span>*</span></h4><br />
                            <div className="custom-control custom-checkbox" >
                                <input type="checkbox"
                                    value="1.1 Bac +2"
                                    onChange={handleChange}
                                    className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1"> Bac +2</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +3"
                                    onChange={handleChange}

                                    type="checkbox" className="custom-control-input" id="customCheck2" />
                                <label className="custom-control-label" htmlFor="customCheck2"> Bac +3</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +4"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck3" />
                                <label className="custom-control-label" htmlFor="customCheck3"> Bac +4</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +5"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck4" />
                                <label className="custom-control-label" htmlFor="customCheck4"> Bac +5</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input

                                    value="1.1 Bac +6"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck5" />
                                <label className="custom-control-label" htmlFor="customCheck5"> Bac +6</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input value="1.1 Bac +7"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck6" />
                                <label className="custom-control-label" htmlFor="customCheck6"> Bac +7</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.1 Bac +8"



                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck7" />
                                <label className="custom-control-label" htmlFor="customCheck7"> Bac +8</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div id="block" >

                            <h4 htmlFor="exampleFormControlSelect1">1.2. Quel domaine de formation souhaitez-vous?
                            </h4><br />
                            <div className="custom-control custom-checkbox" required>
                                <input
                                    value="1.2 Assurance"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck8" />
                                <label className="custom-control-label" htmlFor="customCheck8"> Assurance</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Banque"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck9" />
                                <label className="custom-control-label" htmlFor="customCheck9"> Banque</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Commerce International - Import Export"
                                    onChange={handleChange}


                                    type="checkbox" className="custom-control-input" id="customCheck10" />
                                <label className="custom-control-label" htmlFor="customCheck10"> Commerce International - Import Export</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Communication"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck11" />
                                <label className="custom-control-label" htmlFor="customCheck11"> Communication</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Comptabilité Gestion"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck12" />
                                <label className="custom-control-label" htmlFor="customCheck12"> Comptabilité Gestion</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Gestion de la PME"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck13" />
                                <label className="custom-control-label" htmlFor="customCheck13"> Gestion de la PME</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Management de l'Hôtellerie et de la Restauration"


                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck14" />
                                <label className="custom-control-label" htmlFor="customCheck14"> Management de l'Hôtellerie et de la Restauration</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2  Management de Projets Informatiques"

                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck15" />
                                <label className="custom-control-label" htmlFor="customCheck15"> Management de Projets Informatiques</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Management des Unités Commerciales"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck16" />
                                <label className="custom-control-label" htmlFor="customCheck16"> Management des Unités Commerciales</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Management des Ressources Humaines"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck17" />
                                <label className="custom-control-label" htmlFor="customCheck17"> Management des Ressources Humaines</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Négociation et Digitalisation de la Relation Client"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck18" />
                                <label className="custom-control-label" htmlFor="customCheck18"> Négociation et Digitalisation de la Relation Client</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Notariat"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck19" />
                                <label className="custom-control-label" htmlFor="customCheck19"> Notariat</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Professions Immobilières"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck20" />
                                <label className="custom-control-label" htmlFor="customCheck20"> Professions Immobilières</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Services Informatiques"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck21" />
                                <label className="custom-control-label" htmlFor="customCheck21"> Services Informatiques</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Services à la personne"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck22" />
                                <label className="custom-control-label" htmlFor="customCheck22"> Services à la personne</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Support à l'Action Managériale"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck23" />
                                <label className="custom-control-label" htmlFor="customCheck23"> Support à l'Action Managériale</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.2 Transport Logistique"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck24" />
                                <label className="custom-control-label" htmlFor="customCheck24"> Transport Logistique</label>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div id="block" >

                            <h4 htmlFor="exampleFormControlSelect1">1.3. Quel mode de formation souhaitez-vous? <span>*</span></h4><br />
                            <div className="custom-control custom-checkbox" required>
                                <input
                                    value="1.3 A distance : Accès 24h/24 et 7j/7 à notre plateforme e learning ISMI Campus + 1 heure de suivi tutoré live hebdomadaire"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck25" />
                                <label className="custom-control-label" htmlFor="customCheck25"> A distance : Accès 24h/24 et 7j/7 à notre plateforme e learning ISMI Campus + 1 heure de suivi tutoré live hebdomadaire</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 En alternance :  1 à 2 jours de cours par semaine + présence en entreprise"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck26" />
                                <label className="custom-control-label" htmlFor="customCheck26"> En alternance :  1 à 2 jours de cours par semaine + présence en entreprise</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 En présentiel : au centre de formation et en entreprise"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck29" />
                                <label className="custom-control-label" htmlFor="customCheck29"> En présentiel : au centre de formation et en entreprise</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 Via la validation des acquis par l'expérience (VAE)"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck27" />
                                <label className="custom-control-label" htmlFor="customCheck27"> Via la validation des acquis par l'expérience (VAE)</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 En formation sur mesure (150 euros TTC / heure - Bilan de compétences exigé)"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck28" />
                                <label className="custom-control-label" htmlFor="customCheck28"> En formation sur mesure (150 euros TTC / heure - Bilan de compétences exigé)</label>
                            </div>

                            <div className="custom-control custom-checkbox">
                                <input
                                    value="1.3 Autre :"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck30" />
                                <label className="custom-control-label" htmlFor="customCheck30"> Autre :</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <h3 htmlFor="exampleFormControlSelect1">2. Quel est votre parcours académique et professionnel ?
                        </h3><br />
                        <div id="block" required>

                            <h4 style={{ marginTop: 0 }} htmlFor="exampleFormControlSelect1">2.1. Quel est votre parcours académique? Quel est votre dernier diplôme (RNCP ou Européen) obtenu?
                            </h4><br />

                            <div className="custom-control custom-checkbox" >
                                <input
                                    value="2.1 Bac + 5 ou équivalent"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck31" />
                                <label className="custom-control-label" htmlFor="customCheck31"> Bac + 5 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Bac + 4 ou équivalent"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck32" />
                                <label className="custom-control-label" htmlFor="customCheck32"> Bac + 4 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Bac + 3 ou équivalent"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck33" />
                                <label className="custom-control-label" htmlFor="customCheck33"> Bac + 3 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Bac +2 ou équivalent"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck34" />
                                <label className="custom-control-label" htmlFor="customCheck34"> Bac +2 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox" >
                                <input
                                    value="2.1 Bac +1 ou équivalent"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck35" />
                                <label className="custom-control-label" htmlFor="customCheck35"> Bac +1 ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Baccalauréat ou équivalent"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck36" />
                                <label className="custom-control-label" htmlFor="customCheck36"> Baccalauréat ou équivalent</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Autre diplôme (étranger,...)"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck37" />
                                <label className="custom-control-label" htmlFor="customCheck37"> Autre diplôme (étranger,...)</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.1 Pas de diplôme"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck38" />
                                <label className="custom-control-label" htmlFor="customCheck38"> Pas de diplôme</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div id="block" required>

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">2.2. Dans quelle(s) spécialité(s), votre ou vos diplôme(s) a (ont) - il (s) été obtenu (s)?</h4><br />
                            <input type="text" name="specialiteDiplome"
                                onChange={(e) => handleInput(e)}
                                value={client.specialiteDiplome}
                                style={{ width: "100%", backgroundColor: "cararra" }} className="form-control" placeholder="Spécialité" />
                        </div>

                        <div id="block">

                            <h4 htmlFor="exampleFormControlSelect1">2.3. Etes - vous capable de présenter votre diplôme (ou tout justificatif équivalent : relevé de notes , attestation officielle,...) ou des justificatifs liés à votre expérience professionnelle pour valider votre  inscription à l'examen?</h4><br />

                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="2.3 Oui"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline1" name="customRadioInline1" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline1"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="2.3 Non"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline2" name="customRadioInline1" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline2"> Non</label>
                            </div>

                            <div>
                                <label htmlFor="exampleFormControlSelect1">Si non, pourquoi ?</label><br />
                                <input name="nonPresenterDiplomePourquoi"
                                    onChange={(e) => handleInput(e)}
                                    value={client.nonPresenterDiplomePourquoi}
                                    style={{ width: "100%", backgroundColor: "cararra" }} type="text" className="form-control" />
                            </div>
                        </div>

                        <div id="block" required>
                            <h4 htmlFor="exampleFormControlSelect1">2.5. Combien d'années d'expérience professionnelle pouvez-vous justifier ?
                            </h4><br />
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.5 Plus de 5 ans"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck39" />
                                <label className="custom-control-label" htmlFor="customCheck39"> Plus de 5 ans</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.5 De de 3 à 5 ans"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck40" />
                                <label className="custom-control-label" htmlFor="customCheck40"> De de 3 à 5 ans</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.5 Moins de 3 an"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck41" />
                                <label className="custom-control-label" htmlFor="customCheck41"> Moins de 3 ans</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="2.5 Aucune expérience professionnelle"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck42" />
                                <label className="custom-control-label" htmlFor="customCheck42"> Aucune expérience professionnelle</label>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 offset-md-3">

                        <div id="block">
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">2.6. Dans le cadre de votre expérience professionnelle, quelle(s) ont été vos fonction(s) et pendant combien d'années?</h4><br />
                            <input

                                name="fonctionExperienceProfessionelle"
                                onChange={(e) => handleInput(e)}
                                value={client.fonctionExperienceProfessionelle}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">3. Quel est votre objectif en suivant cette formation?</h3><br />
                        <div id="block" required >
                            <h4 style={{ marginTop: 0 }} htmlFor="exampleFormControlSelect1">3. 1. Pourquoi avez-vous choisi de débuter cette formation ?</h4><br />
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Acquérir des compétences"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck43" />
                                <label className="custom-control-label" htmlFor="customCheck43"> Acquérir des compétences</label>
                            </div>

                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Obtenir un diplôme"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck44" />
                                <label className="custom-control-label" htmlFor="customCheck44"> Obtenir un diplôme</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Poursuivre mes études"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck45" />
                                <label className="custom-control-label" htmlFor="customCheck45"> Poursuivre mes études</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Intégrer une grande école"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck46" />
                                <label className="custom-control-label" htmlFor="customCheck46"> Intégrer une grande école</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1  Effectuer un parcours à l'international"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck47" />
                                <label className="custom-control-label" htmlFor="customCheck47"> Effectuer un parcours à l'international</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Intégrer en fin de parcours une entreprise"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck48" />
                                <label className="custom-control-label" htmlFor="customCheck48"> Intégrer en fin de parcours une entreprise</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="3.1 Autre Raison"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck49" />
                                <label className="custom-control-label" htmlFor="customCheck49"> Autre(s) raison(s)</label>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">3.2. Si autre raison, merci de préciser</h4><br />
                            <input name="autreRaisonObjectifFormation"
                                onChange={(e) => handleInput(e)}
                                value={client.autreRaisonObjectifFormation}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">4. Quelles sont vos attentes et motivations pour cette formation?</h3><br />
                        <div id="block" >
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.a. Qu'attendez-vous de cette formation?</h4><br />
                            <input
                                required
                                name="attentesEtMotivations"
                                onChange={(e) => handleInput(e)}
                                value={client.attentesEtMotivations}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                        {error ?

                            <label className='error'>Ce champ est obligatoire</label>
                            : ""}
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.b. Quelles sont vos motivations pour cette formation ? </h4><br />
                            <input name="motivationsPourSuivreLaFormation"
                                onChange={(e) => handleInput(e)}
                                required
                                value={client.motivationsPourSuivreLaFormation}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                            {error ?

                                <label className='error'>Ce champ est obligatoire</label>
                                : ""}
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.c. Depuis combien de temps vous intéressez-vous à cette formation ?  </h4><br />
                            <input
                                name="depuisCombienDeTempsInteressesParLaFormation"
                                onChange={(e) => handleInput(e)}
                                required
                                value={client.depuisCombienDeTempsInteressesParLaFormation}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" required >

                            <h4 htmlFor="exampleFormControlSelect1">4.d. Combien d'heures par jour pensez-vous pouvoir consacrer à votre travail personnel, en plus des heures de cours liées à la formation?</h4><br />
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Moins d'1h par jour"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck50" />
                                <label className="custom-control-label" htmlFor="customCheck50"> Moins d'1h par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Plus d'1h et moins de 2h par jour"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck51" />
                                <label className="custom-control-label" htmlFor="customCheck51"> 1h à 2h par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Plus de 2h et moins de 3h par jour"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck52" />
                                <label className="custom-control-label" htmlFor="customCheck52"> Plus de 2h à 3h par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Plus de 3h par jour"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck53" />
                                <label className="custom-control-label" htmlFor="customCheck53"> 3h et plus par jour</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="4.d Le temps nécessaires pour obtenir mon diplôme"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck54" />
                                <label className="custom-control-label" htmlFor="customCheck54"> Le temps nécessaire pour obtenir mon diplôme</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.e. D’après vous, quelles sont les qualités nécessaires pour être un bon professionnel ? </h4><br />
                            <input
                                name="qualitesPourEtreUnBonProfessionnel"
                                required
                                onChange={(e) => handleInput(e)}
                                value={client.qualitesPourEtreUnBonProfessionnel}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">4 bis. Uniquement pour les candidats souhaitant un contrat en apprentissage</h3><br />
                        <div id="block" >
                            <h4 style={{ marginTop: 0, marginTop: 0 }} htmlFor="exampleFormControlSelect1">4.bis. a. Avez-vous déjà une entreprise vous ayant proposé un contrat d'apprentissage?</h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis Oui"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline3" name="customRadioInline3" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline3"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis Non"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline4" name="customRadioInline3" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline4"> Non</label>
                            </div>

                        </div>
                    </div>


                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">4.bis. b. Si oui, merci de préciser ci dessous les coordonnées (NOM, Prénom, Téléphone, mail) du contact en entreprise ?</h4><br />
                            <textarea
                                name="coordonneesReferentAlternance"
                                onChange={(e) => handleInput(e)}
                                value={client.coordonneesReferentAlternance}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" >

                            <h4 htmlFor="exampleFormControlSelect1">4.bis. c. Avez-vous vérifié que le poste proposé par l'entreprise correspond bien à la formation que vous avez choisie? Dans tous les cas, merci de transmettre la fiche de poste proposée par l'entreprise pour validation par nos soins.</h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis.c Oui"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline5" name="customRadioInline5" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline5"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="4.bis.c Non"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline6" name="customRadioInline5" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline6"> Non</label>
                            </div>
                            <div className="custom-file">
                                <h4 style={{ marginTop: 40, }} htmlFor="exampleFormControlSelect1">Fiche de poste</h4><br />

                                <input type="file"
                                    name="ficheDePosteMedia"
                                    required
                                    onChange={(e) => setFicheDePosteMedia(e.target.files[0])}
                                    // value={formData.cniMedia}
                                    class="form-control" id="customFile" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">5. Avez-vous des besoins spécifiques, par exemple, liés à une situation de handicap?</h3><br />
                        <div id="block" >
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">5. Si vous avec des besoins spécifiques (situation de handicap), pouvez-vous nous les présenter? </h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="5. Oui"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline7" name="customRadioInline7" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline7"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="5. Non"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline8" name="customRadioInline7" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline8"> Non</label>
                            </div>
                            <div style={{ marginTop: "20px" }}>

                                <input
                                    name="besoinsCreationActivite"
                                    onChange={(e) => handleInput(e)}
                                    value={client.besoinsCreationActivite}
                                    placeholder='Si oui, merci de préciser'
                                    type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">


                        <h3 htmlFor="exampleFormControlSelect1">6. Observations complémentaires</h3><br />
                        <div id="block" required >
                            <h4 style={{ marginTop: 0 }} htmlFor="exampleFormControlSelect1">6.a. Avez-vous conscience que votre projet de formation nécessite, selon le profil de chaque candidat, entre 600 et 1100h de formation?</h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="6. Oui"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline10" name="customRadioInline10" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline10"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="6. Non"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline11" name="customRadioInline10" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline11"> Non</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">

                        <div id="block" required >

                            <h4 htmlFor="exampleFormControlSelect1">6.b. Disposez-vous d'un support numérique (ordinateur, téléphone, tablette, ...) vous permettant de vous connecter à internet ?</h4><br />
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="6.b Ordinateur fixe de bureau"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck55" />
                                <label className="custom-control-label" htmlFor="customCheck55"> Ordinateur fixe de bureau</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="6.b Ordinateur portable"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck56" />
                                <label className="custom-control-label" htmlFor="customCheck56"> Ordinateur portable</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="6.b Tablette numérique"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck57" />
                                <label className="custom-control-label" htmlFor="customCheck57"> Tablette numérique</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="6.b Téléphone portable personnel"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck58" />
                                <label className="custom-control-label" htmlFor="customCheck58"> Téléphone portable personnel </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    value="6.b Autre"
                                    onChange={handleChange}
                                    type="checkbox" className="custom-control-input" id="customCheck59" />
                                <label className="custom-control-label" htmlFor="customCheck59"> Autre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div div id="block">

                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">6.c. Votre avenir professionnel...Avez-vous besoin d’un accompagnement à la création d’activité par la suite ?</h4><br />
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="6. Oui"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline12" name="customRadioInline12" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline12"> Oui</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    value="6. Non"
                                    onChange={handleChange}
                                    type="radio" id="customRadioInline13" name="customRadioInline12" className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline13"> Non</label>
                            </div>
                            <div style={{ marginTop: "20px" }}>

                                <input
                                    name="besoinsCreationActivite"
                                    onChange={(e) => handleInput(e)}
                                    value={client.besoinsCreationActivite}
                                    placeholder='Si oui, merci de détailler'
                                    type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <div div id="block">
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">6.d. Souhaitez-vous nous transmettre d'autres informations complémentaires ?</h4><br />
                            <input
                                name="autresInformationsComplementaires"
                                onChange={(e) => handleInput(e)}
                                value={client.autresInformationsComplementaires}
                                type="text" className="form-control" style={{ width: "100%", backgroundColor: "cararra" }} />
                        </div>
                    </div>

                    <div className="col-md-6 offset-md-3">
                        <h3 style={{ marginBottom: 0 }} htmlFor="exampleFormControlSelect1">7. Téléchargez vos fichiers</h3><br />

                        <div id="block" style={{ margin: "30px" }}>
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">CV</h4><br />

                            <div className="input-group mb-3">
                                {/* <div className="input-group-prepend">
                                    <span className="input-group-text">Télécharger</span>
                                </div> */}
                                <div className="custom-file">
                                    {/* <input type="file" className="custom-file-input" id="inputGroupFile01" /> */}
                                    <input type="file"
                                        name="cvMedia"
                                        required
                                        onChange={(e) => setCvMedia(e.target.files[0])}
                                        // value={formData.cvMedia}
                                        class="form-control" id="customFile" />
                                    {/* <label className="custom-file-label" for="inputGroupFile01"></label> */}
                                </div>
                            </div>
                        </div>
                        <div id="block" style={{ margin: "30px" }}>
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Dernier diplôme</h4><br />

                            <div className="custom-file">

                                <input type="file"
                                    name="diplomeMedia"
                                    required
                                    onChange={(e) => setDiplomeMedia(e.target.files[0])}
                                    // value={formData.diplomeMedia}
                                    class="form-control" id="customFile" />
                            </div>
                        </div>

                        <div id="block" style={{ margin: "30px" }}>
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Lettre de motivation</h4><br />

                            <div className="custom-file">

                                <input type="file"
                                    name="lettreDeMotivationMedia"
                                    required
                                    onChange={(e) => setLettreDeMotivationMedia(e.target.files[0])}
                                    // value={formData.lettreDeMotivationMedia}
                                    class="form-control" id="customFile" />
                            </div>
                        </div>

                        <div id="block" style={{ margin: "30px" }}>
                            <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Pièce d'identité</h4><br />

                            <div className="custom-file">

                                <input type="file"
                                    name="cniMedia"
                                    required
                                    onChange={(e) => setCniMedia(e.target.files[0])}
                                    // value={formData.cniMedia}
                                    class="form-control" id="customFile" />
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 offset-md-3" >
                        <h3 htmlFor="exampleFormControlSelect1">Signature</h3><br />

                        <h4 style={{ marginTop: 0, marginBottom: 0 }} htmlFor="exampleFormControlSelect1">Merci de signer ce formulaire</h4><br />
                        <div id="block">

                            <div style={{ width: "100%", height: 250, border: "1px, solid, black" }}>

                                <SignatureCanvas
                                    required
                                    canvasProps={{ width: 350, height: 250, className: 'sigCanvas' }}
                                    ref={data => setSign(data)}
                                />
                            </div>
                            {/*                             
                             <button style={{ margin: "30px", backgroundColor: "#94B9D9" }} className="btn btn-primary" onClick={handleClear}>Effacer</button> */}
                        </div>
                    </div>
                    {/* <img src={url} /> */}
                    <div className="col-md-6 offset-md-3">
                        <button style={{ margin: "30px", backgroundColor: "#94B9D9" }} className="btn btn-primary" onClick={(e) => handleSave(e)} type="submit">Étape Suivante</button>

                        {/* <button onClick={() => navigate('/payment')} style={{ margin: "30px", backgroundColor: "#94B9D9" }} className="btn btn-primary">
                            Régler les frais d'inscription
                        </button> */}
                        {/* <Payment /> */}
                    </div>
                </div>
            </form >
        </div >
    )
}