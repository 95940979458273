import React, { image } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import banniere from "../../images/PictureISMI.png"
import signature from "../../images/SignatureISMI.png"
// Create styles
const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        paddingHorizontal: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 12,
        textAlign: "center",
        fontSize: "13px"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "80%",
    },
    right: {
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "2.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10px",
        marginTop: "50px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    }
});

// Create Document Component
export const AttestationDeSuiviEtDeFormation = (props) => (
    <Document>
        <Page size="A4"
            style={styles.page}
        >
            <View>

                <Image
                    //style={styles.image} 
                    src={banniere}
                />
            </View>
            <View style={styles.left}>

                <Text

                >N° d’enregistrement auprès du préfet IDF :
                </Text><br />
                <Text >

                    11 75 44 681 75
                </Text>
            </View>
            <View>

                <Text
                    style={styles.right}
                >
                    Paris, Le : {new Intl.DateTimeFormat('fr-fr').format(Date.now())},</Text><br />
            </View>
            <View>

                <Text
                    style={{ margin: "50px" }}
                >ATTESTATION DE SUIVI ET DE FIN DE FORMATION</Text><br />
            </View>
            <View >
                <Text
                    style={styles.text}
                >L’Institut de Stratégie et de Management International, représenté par Madame Audrey <br />
                    DUONG, responsable des formations, atteste, par la présente, que :</Text><br />
                <Text
                    style={styles.text}
                >Mr/Mme {props.prenom} {props.nom}</Text><br />
                <Text
                    style={styles.text}
                >suit l’équivalent de 1 100h de formation professionnelle continue en vue d’obtenir le diplôme <br />
                    certifié par l’Etat et enregistré au RNCP de {props.nomDeLeFormation} <br />
                    La formation se déroule sur l’année académique 2023 - 2024.</Text><br />
            </View>
            <View >
                <Text
                    style={styles.right}
                >Pour faire valoir ce que de droit,</Text><br />


            </View>
            <View><Image src={signature} style={styles.signature} /></View><br />
            <View><Text
                style={styles.right}
            >
                Madame Audrey DUONG <br />
            </Text> <br />
                <Text style={styles.right}>
                    Directrice.
                </Text>
            </View>
            <View
                style={styles.footer}
            >
                <Text>

                    © Institut de Stratégie et de Management International - Mai 2024 </Text><br />
                <Text>

                    Tél: 01 71 20 45 88, Site internet : https://www.ismi.fr, E mail : contact@ismi.fr Siret: 50925025400018
                </Text><br />
                <Text>

                    Accueil sur RDV de 15h à 18h du lundi au vendredi à ISMI, Tour Essor, Rte des Petits Ponts,75019 Paris
                </Text><br />
                <Text>

                    Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75 020 Paris
                </Text><br />
                <Text>

                    Code APE : 7022Z N°d’enregistrement auprès de la préfecture: 11754468175 - Marketies
                </Text><br />
                <Text>

                    Copyright réservé - Reproduction strictement interdite sans accord express écrit validé par l’ISMI
                </Text>

            </View>
        </Page>
    </Document >
);