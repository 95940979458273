import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { CertificatDInscription } from '../PDFs/CertificatDInscription';
import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        paddingHorizontal: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 12,
        textAlign: "center",
        fontSize: "13px"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "80%",
    },
    right: {
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "2.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10px",
        marginTop: "50px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    }
});

export class ProfilCandidat extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.signature = signature;
    }



    createNotification = () => {
        return NotificationManager.success('Success message', "L'email a bien été envoyé!");
    }

    //notify = () => toast("L'email a été envoyé avec succès!");

    // handleComplianceEmail() {
    //     // emailjs.init({
    //     //     publicKey: ,
    //     // });

    // }




    handleSubmit(event) {
        event.preventDefault();
        // fetch(process.env.REACT_APP_API + 'posts', {
        //     method: 'PUT',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         PostId: event.target.PostId.value,
        //         PostName: event.target.PostName.value,
        //         Category: event.target.Category.value,
        //         PostDescription: event.target.PostDescription.value,
        //         PostYoutubeHref: event.target.PostYoutubeHref.value,
        //         AdsTitle: event.target.AdsTitle.value,
        //         AdsImageFileName: this.photofilename,
        //         AdsLink: event.target.AdsLink.value
        //     })
        // })
        //     .then(response =>
        //         response.json()
        //     )
        //     .then((result) => {
        //         alert(result);
        //     })
        //     .catch(error => {
        //         console.error(error.message)
        //     });
        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        });
        try {

            // var base64 = canvas.toDataURL();

            //console.log(canvas)

            emailjs.send("service_2dzzalg", "validation_candidature", {
                from_name: "ISMI",
                to_name: `${this.props.prenom} ${this.props.nom}`,
                recipient: `${this.props.email}`,
                // content: base64
            });

            this.createNotification();

            axios.put(process.env.REACT_APP_API_URL + this.props.clientid, {
                inscrit: true,
                dateDebutDeFormation: this.props.dateDebutDeFormation,
                dateFinDeFormation: this.props.dateFinDeFormation
            }).then(response => console.log(response.data))
                .catch(error => console.error(error));
            //this.navigate('/dashboard')
        }
        catch (e) {
            console.log(e)
        } finally {
            console.log("Email sent")
        }

    }

    render() {

        return (
            <div className="container">
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Valider une candidature
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form onSubmit={this.handleSubmit}>
                                    {this.props ?

                                        (
                                            <div>

                                                <Form.Group controlId="ClientId">
                                                    <Form.Label>ID du candidat</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="ClientId"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.clientid}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Nom">
                                                    <Form.Label>Nom</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nom"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.nom}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Prenom">
                                                    <Form.Label>Prénom</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="prenom"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.prenom}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        required
                                                        defaultValue={this.props.email}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Numero">
                                                    <Form.Label>Numéro de téléphone</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="numero"
                                                        required
                                                        disabled

                                                        defaultValue={this.props.numero}
                                                    />

                                                </Form.Group>
                                                {/* <Form.Group controlId="CreneauHoraire">
                                                    <Form.Label>Disponibilités</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="creneauHoraire"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.creneauHoraire}
                                                    />

                                                </Form.Group> */}
                                            </div>
                                        ) : 'null'
                                    }
                                    <Form.Group controlId="Questionnaire">
                                        <h2>Identification besoin candidat</h2>
                                        {this.props.checkboxesQuestionnaire ?

                                            //  this.props.checkboxesQuestionnaire.map(

                                            (<><div>

                                                {/* <p>
                                                    <h3>1. Par quel(s) type(s) de formation(s) êtes-vous intéressé (e)?</h3><br />

                                                    <p>
                                                        {this.props.checkboxesQuestionnaire.filter(item => item.includes("1.1 ")).map(filtered => <p>Niveau de formation souhaité: {filtered}</p>)}<br />
                                                    </p>
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("1.2 ")).map(filtered => <p>Domaine de formation: {filtered}</p>)}<br />
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("1.3 ")).map(filtered => <p>Mode de formation souhaité: {filtered}</p>)}<br />
                                                </p>
                                                <p>

                                                    <h3>2. Quel est votre parcours académique et professionnel ?</h3><br />
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("2.1 ")).map(filtered => <p>Dernier diplôme: {filtered}</p>)}<br />
                                                    Spécialité dernier diplôme: {this.props.specialiteDiplome}
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("2.3 ")).map(filtered => <p>Capable de présenter diplôme: {filtered}</p>)}<br />
                                                    Non, pourquoi: {this.props.nonPresenterDiplomePourquoi}
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("2.5 ")).map(filtered => <p>Années expérience profesionnelle: {filtered}</p>)}<br />
                                                </p>
                                                <p>

                                                    <h3>3. Quel est votre objectif en suivant cette formation?</h3><br />
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("3.1")).map(filtered => <p>Pourquoi cette formation:    {filtered}</p>)}<br />
                                                    Autre objectif de formation: {this.props.autreObjectif}<br />
                                                </p>


                                                <p>

                                                    <h3>4. Quelles sont vos attentes et motivations pour cette formation?</h3><br />
                                                    Attente de la formation: {this.props.attenteDeLaFormation}<br />
                                                    Motivation: {this.props.motivation}<br />
                                                    Depuis combien de temps intéressé par la formation: {this.props.depuisCombienDeTempsInteressesParLaFormation}<br />
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("4.d")).map(filtered => <p>Combien de temps à consacrer: {filtered}</p>)}<br />

                                                </p>
                                                <p>

                                                    <h3>4 bis. Uniquement pour les candidats souhaitant un contrat en apprentissage</h3><br />
                                                    Coordonnées référent alternance: {this.props.coordonneesReferentAlternance}<br />
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("4.bis.c")).map(filtered => <p>Poste correspond-t-il a votre formation: {filtered}</p>)}<br />
                                                    Qualité être un bon profesionnel: {this.props.qualitesPourEtreUnBonProfessionnel} <br />

                                                </p>
                                                <p>

                                                    <h3>5. Avez-vous des besoins spécifiques, par exemple, liés à une situation de handicap?</h3><br />
                                                    {this.props.besoinsSpecifiquesHandicap}<br />
                                                </p>
                                                <p>

                                                    <h3>6. Observations complémentaires</h3><br />
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("6.a")).map(filtered => <p>compréhension formation demande 600 à 1100h: {filtered}</p>)}<br />
                                                    {this.props.checkboxesQuestionnaire.filter(item => item.includes("6.b")).map(filtered => <p>Supports numériques: {filtered}</p>)}<br />
                                                    Besoins pour la création d'activité: {this.props.besoinsCreationActivite}<br />
                                                    Informations complémentaires: {this.props.autresInformationsComplementaires}<br />
                                                </p> */}
                                                <p>
                                                    {this.props.checkboxesQuestionnaire.map(filtered => <li>{filtered}</li>)
                                                    }
                                                </p><br />
                                            </div>
                                            </>) : "Null"}

                                        {/* console.log(this.props.checkboxesQuestionnaire) */}



                                    </Form.Group>
                                    <Form.Group controlId="Documents">
                                        <h2>Documents à télécharger</h2>
                                        {/* <Form.Control
type="text"
name="ImageFileName"
required
defaultValue={this.props.postimagefilename}
/> */}
                                        <div style={{ margin: "20px" }}>


                                            <ol>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.cniMedia}>CV</a><br />
                                                </li>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.diplomeMedia}>Diplôme</a><br />
                                                </li>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.lettreDeMotivationMedia}>Lettre de motivation</a><br />
                                                </li>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.cniMedia}>Pièce d'identié</a><br />
                                                </li>
                                                <li>
                                                    <a>Signature</a>
                                                    <iframe src={this.props.signatureUrl} frameborder="0" style={{ border: 0, top: "0px", left: "0px", bottom: "0px", right: "0px", width: "100%", height: "100%" }} allowfullscreen></iframe><br />
                                                </li>

                                            </ol>
                                        </div>

                                    </Form.Group>
                                    <Form.Group controlId="DateDebutDeFormation">
                                        <Form.Label>Date de début de formation</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="dateDebutDeFormation"
                                            required


                                            defaultValue={this.props.dateDebutDeFormation}
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="DateFinDeFormation">
                                        <Form.Label>Date de fin de formation</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="dateFinDeFormation"
                                            required

                                            defaultValue={this.props.dateFinDeFormation}
                                        />

                                    </Form.Group>

                                    <Form.Group>
                                        <Button variant="primary" type="submit">
                                            Valider la candidature

                                        </Button>
                                        <NotificationContainer />
                                    </Form.Group>
                                </Form>
                            </Col >
                        </Row >
                    </Modal.Body > <Modal.Footer>
                        <Button variant="danger" onClick={this.props.onHide}>Fermer</Button>
                    </Modal.Footer>
                </Modal >
            </div >

        );
    }
}
