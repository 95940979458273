import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import banniere from "../../images/PictureISMI.png"
import DashBoard from "../../pages/Dashboard"

import signature from "../../images/SignatureISMI.png"

// Create styles
const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        paddingHorizontal: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",
        margin: 12,
        textAlign: "center",
        fontSize: "13px"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "80%",
    },
    right: {
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "2.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10px",
        marginTop: "50px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    }
});

// Create Document Component
export const AttestationDeSuiviEtDeFinDeFormation = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>

                <Image
                    //style={styles.image} 
                    src={banniere}
                />
            </View>
            <View style={styles.left}>

                <Text>N° d’enregistrement auprès du préfet IDF :</Text> <br />
                <Text>11 75 44 681 75</Text>
            </View>
            <View style={styles.right}>

                <Text>Paris, Le : {new Intl.DateTimeFormat('fr-fr').format(Date.now())},</Text>
            </View>
            <View style={{ margin: "50px" }}>

                <Text>ATTESTATION DE SUIVI ET DE FIN DE FORMATION</Text>
            </View>
            <View >
                <Text style={styles.text}>est entré(e ) et a suivi l’équivalent de 1 100h de formation professionnelle continue en vue <br />
                    d’obtenir le diplôme certifié par l’Etat et enregistré au RNCP de <br />
                    niveau 5, intitulé BTS MCO Brevet de Technicien Supérieur – Spécialité « Management Commercial Opérationnel » <br />
                    La formation s’est déroulée sur l’année académique 2023 - 2024.<br />
                </Text>
                <Text style={styles.text}>Sous réserve de transmission des documents réglementaires exigés par le ministère <br />
                    et/ou le certificateur au mois d’octobre chaque année, la présente attestation permet à <br />
                    l’intéressé (e ) d’accéder à l’inscription en candidat libre pour le passage du diplôme précité <br />
                    pendant 4 années supplémentaires en sus de cette actuelle année académique.<br />
                </Text>
            </View>
            <View style={styles.right}>
                <Text>Pour faire valoir ce que de droit,</Text>


            </View>
            <View>

                <Image
                    style={styles.signature}
                    src={signature}
                />
            </View>
            <View>
                <Text style={styles.right}>Madame Audrey DUONG</Text> <br />
                <Text style={styles.right}>
                    Directrice.
                </Text>
            </View>
            <View
                style={styles.footer}
            >
                <Text>

                    © Institut de Stratégie et de Management International - Mai 2024 </Text><br />
                <Text>

                    Tél: 01 71 20 45 88, Site internet : https://www.ismi.fr, E mail : contact@ismi.fr Siret: 50925025400018
                </Text><br />
                <Text>

                    Accueil sur RDV de 15h à 18h du lundi au vendredi à ISMI, Tour Essor, Rte des Petits Ponts,75019 Paris
                </Text><br />
                <Text>

                    Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75 020 Paris
                </Text><br />
                <Text>

                    Code APE : 7022Z N°d’enregistrement auprès de la préfecture: 11754468175 - Marketies
                </Text><br />
                <Text>

                    Copyright réservé - Reproduction strictement interdite sans accord express écrit validé par l’ISMI
                </Text>

            </View>
        </Page >
    </Document >
);